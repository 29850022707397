import React, { useState } from "react";
import { Button, Box, Flex, Text } from "theme-ui";
import { motion } from "framer-motion";
import {
	Elements,
	CardElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	process.env.NODE_ENV === "development"
		? "pk_test_3UCjuEcc9rlp3wT2TohC9B0H00AK5koaqP"
		: "pk_live_q2ldtDv3VD50vkzYtrGMVuoZ00XkhkVYYl"
);

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#666",
			color: "#111",
			fontWeight: 500,
			fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": {
				color: "#fce883"
			},
			"::placeholder": {
				color: "#666"
			}
		},
		invalid: {
			iconColor: "#f00",
			color: "#f00"
		}
	}
};

const CardField = ({ onChange }) => (
	<Box sx={{ mb: [3] }} className='FormRow'>
		<CardElement options={CARD_OPTIONS} onChange={onChange} />
	</Box>
);

const Field = ({
	label,
	id,
	type,
	placeholder,
	required,
	autoComplete,
	value,
	onChange
}) => (
	<Flex sx={{ mb: [3], alignItems: "baseline" }} className='FormRow'>
		<Box as='label' htmlFor={id} className='FormRowLabel'>
			{label}
		</Box>
		<Box
			sx={{ borderColor: "transparent", ml: [3], fontSize: [2], width: "100%" }}
			as='input'
			className='FormRowInput'
			id={id}
			type={type}
			placeholder={placeholder}
			required={required}
			autoComplete={autoComplete}
			value={value}
			onChange={onChange}
		/>
	</Flex>
);

const SubmitButton = ({ processing, error, children, disabled }) => {
	return (
		<Button
			sx={{
				cursor: "pointer",
				mt: [3],
				border: !error ? "1px solid black" : "1px solid red",
				color: !error ? "black" : "#f00",
				width: "100%",
				bg: "white",
				"&:hover": {
					bg: "black",
					color: "white"
				}
			}}
			className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
			type='submit'
			disabled={processing || disabled}>
			{processing ? "Processing..." : children}
		</Button>
	);
};

const ErrorMessage = ({ children }) => (
	<Box
		sx={{
			border: "1px solid transparent",
			borderColor: "red.200",
			p: [2],
			borderRadius: [4],
			bg: "red.100",
			color: "red.600",
			fontSize: [1]
		}}
		className='ErrorMessage'
		role='alert'>
		{children}
	</Box>
);

const ResetButton = ({ onClick }) => (
	<Button
		sx={{
			mt: [3],
			color: "black",
			width: "100%"
		}}
		type='button'
		className='ResetButton'
		onClick={onClick}>
		<svg width='32px' height='32px' viewBox='0 0 32 32'>
			<path
				fill='currentColor'
				d='M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z'
			/>
		</svg>
	</Button>
);

const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState(null);
	const [fetching, setFetching] = useState(null);
	const [success, setSuccess] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [cardComplete, setCardComplete] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [billingDetails, setBillingDetails] = useState({
		email: process.env.NODE_ENV === "development" ? "test@mail.de" : "",
		name: process.env.NODE_ENV === "development" ? "test name" : ""
	});

	React.useEffect(() => {
		// Send data to our custom api endpoint
		// TODO: Create function to automatically get current ENV
		const LOCAL_PREORDER_API =
			"http://localhost:5001/supertime-staging/us-central1/interaction";
		const STAGING_PREORDER_API =
			"https://staging.supertime.app/api/interaction";
		const PRODUCTION_PREORDER_API = "https://web.supertime.app/api/interaction";

		const API =
			process.env.NODE_ENV === "development"
				? STAGING_PREORDER_API
				: PRODUCTION_PREORDER_API;

		fetch(API, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ interaction: "PREORDER_CLICK" })
		});
	}, []);

	const handleSubmit = async event => {
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		if (error) {
			elements.getElement("card").focus();
			return;
		}

		if (cardComplete) {
			setProcessing(true);
		}

		const payload = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement),
			billing_details: billingDetails
		});

		setProcessing(false);

		if (payload.error) {
			setError(payload.error);
		} else {
			setFetching(true);

			// Send data to our custom api endpoint
			const LOCAL_PREORDER_API =
				"http://localhost:5001/supertime-staging/us-central1/createPreorderCustomer";
			const STAGING_PREORDER_API =
				"https://staging.supertime.app/api/createPreorderCustomer";
			const PRODUCTION_PREORDER_API =
				"https://web.supertime.app/api/createPreorderCustomer";

			const API =
				process.env.NODE_ENV === "development"
					? STAGING_PREORDER_API
					: PRODUCTION_PREORDER_API;

			const res = await fetch(API, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(payload)
			});

			const json = await res.json();
			if (json.status === 200) {
				setSuccess(true);

				try {
					typeof window !== "undefined" &&
						//@ts-ignore
						window.gtag("event", "conversion", {
							send_to: "AW-655255057/m7miCPnz4csBEJHMubgC"
						});
				} catch (error) {
					if (error) console.log(error);
				}
			}
		}
	};

	const reset = () => {
		setError(null);
		setProcessing(false);
		setPaymentMethod(null);
		setBillingDetails({
			email: "",
			name: ""
		});
	};

	if (success) {
		// TODO: success state
		return (
			<Box
				sx={{
					maxWidth: [400],
					mx: "auto",
					p: [3],
					boxShadow: "0px 25px 50px rgba(0, 0, 0, 0.25)",
					borderRadius: 4
				}}
				className='Result'>
				<Text
					sx={{
						textAlign: "center",
						fontWeight: "bold",
						mb: [3],
						fontSize: [3]
					}}
					className='ResultTitle'
					role='alert'>
					Your preorder was successfully submitted!
				</Text>
				<div className='ResultMessage'>
					No money will be charged until you start using the product.
				</div>
			</Box>
		);
	}

	if (fetching) {
		// TODO: Add fetching state
		return (
			<Box
				sx={{
					maxWidth: [400],
					mx: "auto",
					p: [3],
					boxShadow: "0px 25px 50px rgba(0, 0, 0, 0.25)",
					borderRadius: 4
				}}
				className='Result'>
				<Text
					sx={{
						textAlign: "center",
						fontWeight: "bold",
						mb: [3],
						fontSize: [3]
					}}
					className='ResultTitle'
					role='alert'>
					Submitting data
				</Text>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				maxWidth: [400],
				mx: "auto",
				p: [3],
				boxShadow: "0px 25px 50px rgba(0, 0, 0, 0.25)",
				borderRadius: 4
			}}
			as='form'
			className='Form'
			onSubmit={handleSubmit}>
			<fieldset className='FormGroup'>
				<Field
					label='Name'
					id='name'
					type='text'
					placeholder='Jane Doe'
					required
					autoComplete='name'
					value={billingDetails.name}
					onChange={e => {
						setBillingDetails({ ...billingDetails, name: e.target.value });
					}}
				/>
				<Field
					label='Email'
					id='email'
					type='email'
					placeholder='janedoe@gmail.com'
					required
					autoComplete='email'
					value={billingDetails.email}
					onChange={e => {
						setBillingDetails({ ...billingDetails, email: e.target.value });
					}}
				/>
			</fieldset>
			<fieldset className='FormGroup'>
				<CardField
					onChange={e => {
						setError(e.error);
						setCardComplete(e.complete);
					}}
				/>
			</fieldset>
			{error && <ErrorMessage>{error.message}</ErrorMessage>}
			<SubmitButton processing={processing} error={error} disabled={!stripe}>
				Preorder now
			</SubmitButton>

			<Text sx={{ mt: [3], fontSize: [1], textAlign: "center" }}>
				You only will be charged once you start using the product.
			</Text>
		</Box>
	);
};

const StripePreorder = () => {
	return (
		<motion.div
			layoutTransition
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}>
			<Elements stripe={stripePromise}>
				<CheckoutForm />
			</Elements>
		</motion.div>
	);
};

export default StripePreorder;
