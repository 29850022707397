import React from "react";

const ScoreIcon = props => (
	<svg width={131} height={130} viewBox='0 0 131 130' fill='none' {...props}>
		<path
			d='M67.944 0H.5v130h130V0H67.944zm-4.888 125.113H5.387v-57.67h57.67v57.67zm0-62.557H5.387V4.887h57.67v57.67zm62.557 62.557h-57.67v-57.67h57.67v57.67zm0-62.557h-57.67V4.887h57.67v57.67z'
			fill='#48BB78'
		/>
		<path
			d='M31.778 49.362h4.887V36.166h13.196V31.28H36.665V18.084h-4.887v13.195H18.583v4.887h13.195v13.196zM112.417 31.278H81.139v4.887h31.278v-4.887zM112.417 86.016H81.139v4.887h31.278v-4.887zM112.417 101.653H81.139v4.888h31.278v-4.888zM20.294 113.627l13.928-13.88 13.929 13.88 3.42-3.421-13.879-13.928 13.88-13.929-3.421-3.42-13.929 13.879-13.928-13.88-3.421 3.421 13.88 13.929-13.88 13.928 3.42 3.421z'
			fill='#48BB78'
		/>
	</svg>
);

export default ScoreIcon;
