import React from "react";

const GoalIcon = props => (
	<svg width={130} height={130} viewBox='0 0 130 130' fill='none' {...props}>
		<path
			d='M100.693 44.255l-3.534 3.529a43.231 43.231 0 016.214 22.377c0 23.996-19.537 43.518-43.551 43.518-24.014 0-43.553-19.522-43.553-43.518 0-23.997 19.539-43.52 43.553-43.52 11.123 0 21.279 4.201 28.983 11.084l-6.338 6.335c-6.074-5.28-13.982-8.497-22.645-8.497-19.092 0-34.624 15.52-34.624 34.598 0 19.076 15.532 34.596 34.624 34.596 19.09 0 34.622-15.52 34.622-34.596 0-5.698-1.41-11.062-3.86-15.806l-3.64 3.636a29.52 29.52 0 012.635 12.17c0 16.395-13.35 29.733-29.756 29.733-16.407 0-29.756-13.34-29.756-29.733 0-16.396 13.35-29.735 29.756-29.735 7.322 0 14.023 2.666 19.208 7.066l-6.348 6.345c-3.543-2.802-8.001-4.489-12.86-4.489-11.485 0-20.828 9.336-20.828 20.813 0 11.475 9.343 20.81 20.828 20.81 11.484 0 20.827-9.335 20.827-20.81 0-1.798-.253-3.531-.683-5.196l-4.233 4.23c.02.322.049.64.049.966 0 8.794-7.16 15.948-15.96 15.948s-15.96-7.156-15.96-15.948c0-8.796 7.16-15.95 15.96-15.95 3.516 0 6.749 1.164 9.384 3.1L58.068 68.44l.034.034a2.4 2.4 0 00-.69 1.686 2.41 2.41 0 004.097 1.72l.003-.004.03-.027 39.898-39.873 9.44 2.932L130 15.804l-12.065-3.748L114.185 0 95.067 19.106 98 28.536l-5.747 5.744c-8.003-7.231-18.42-11.838-29.887-12.435V10.441H57.28v11.404C32.583 23.13 12.723 42.992 11.468 67.677H0v5.084h11.476c1.312 24.632 21.146 44.434 45.804 45.717V130h5.087v-11.522c24.658-1.283 44.49-21.084 45.802-45.717h11.478v-5.084h-11.468a48.053 48.053 0 00-7.486-23.422zm15.982-27.498l4.187 1.3-11.344 11.334-4.187-1.3 11.344-11.334zm-4.744-7.624l1.302 4.18-11.346 11.338-1.301-4.184 11.345-11.334z'
			fill='#48BB78'
		/>
	</svg>
);

export default GoalIcon;
