import React from "react";

const TaskIcon = props => (
	<svg width={137} height={130} viewBox='0 0 137 130' fill='none' {...props}>
		<path
			d='M78.41 96.353h8.06V78h-8.06a35.575 35.575 0 00-3.7-8.901l5.643-5.72L67.445 50.47l-5.705 5.704a35.558 35.558 0 00-8.916-3.762v-8.06H34.47v8.06a35.559 35.559 0 00-8.902 3.701l-5.72-5.643L6.941 63.379l5.705 5.705A35.529 35.529 0 008.883 78H.823v18.353h8.06a35.552 35.552 0 003.702 8.901l-5.644 5.72 12.908 12.908 5.705-5.704a35.583 35.583 0 008.917 3.762V130h18.353v-8.06a35.56 35.56 0 008.9-3.701l5.72 5.643 12.909-12.908-5.705-5.705a35.558 35.558 0 003.763-8.916zm-4.48 14.621l-6.485 6.485-5.063-5.078-1.53 1.025a31.053 31.053 0 01-10.705 4.466l-1.912.382v7.158H39.06v-7.158l-1.82-.382a31.053 31.053 0 01-10.706-4.466l-1.53-1.025-5.154 5.078-6.484-6.485 5.062-5.062-1.025-1.53a31.029 31.029 0 01-4.466-10.706l-.367-1.911H5.412v-9.177h7.157l.383-1.835a31.033 31.033 0 014.466-10.706l1.024-1.53-5.077-5.138 6.484-6.485 5.063 5.063 1.53-1.025a31.03 31.03 0 0110.705-4.466l1.912-.367V48.94h9.176V56.1l1.82.382a31.03 31.03 0 0110.706 4.466l1.53 1.025 5.062-5.063 6.485 6.485-5.063 5.063 1.025 1.529a31.076 31.076 0 014.466 10.706l.459 1.896h7.157v9.177h-7.157l-.383 1.82a31.08 31.08 0 01-4.466 10.706l-1.024 1.529 5.077 5.154z'
			fill='#48BB78'
		/>
		<path
			d='M43.647 61.176a26 26 0 100 51.999 26 26 0 000-51.999zm0 47.412a21.41 21.41 0 01-15.14-36.552 21.412 21.412 0 1115.14 36.552zM93.353 4.588a35.94 35.94 0 110 71.883v4.588a40.53 40.53 0 10-40.53-40.53h4.589a35.987 35.987 0 0135.94-35.94z'
			fill='#48BB78'
		/>
		<path
			d='M91.059 16.059V34.87a6.117 6.117 0 107.953 7.953h18.811v-4.588H99.012a6.118 6.118 0 00-3.365-3.365V16.06h-4.588zM136.176 97.118H91.824v4.588h44.352v-4.588zM118.588 109.353H82.647v4.588h35.941v-4.588zM135.412 109.353h-11.471v4.588h11.471v-4.588z'
			fill='#48BB78'
		/>
	</svg>
);

export default TaskIcon;
