/** @jsx jsx */
import { jsx, Heading, Text, Input, Button, Flex, Box } from "theme-ui";
import React from "react";
import dayjs from "dayjs";
import { Parallax } from "react-scroll-parallax";
import { useInterval } from "react-use";
import { AnimatePresence } from "framer-motion";

import {
	// SliceZone,
	Header
	// Footer
} from "../components";
// import website from '../../config/website';
import { LocaleContext } from "../components/Layout";
import SEO from "../components/SEO";
import { Image } from "../components/Image/Image";
import ScoreIcon from "../components/Icons/ScoreIcon";
import GoalIcon from "../components/Icons/GoalIcon";
import TaskIcon from "../components/Icons/TaskIcon";
import StripePreorder from "../components/StripePreorder/StripePreorder";

const H = props => (
	<Heading
		color='black'
		mt={[4, 5]}
		mb={[3, 4, 5]}
		{...props}
		sx={{
			fontSize: [5, 6, "5.5vw"],
			fontWeight: "400",
			textAlign: "center",
			...props.sx
		}}
	/>
);

const P = props => (
	<Text
		color='gray.800'
		mb={[3, 4, 5]}
		{...props}
		sx={{ fontSize: [4, 5, "2.5vw"], ...props.sx }}
	/>
);

const S = props => (
	<Text
		color='gray.400'
		mb={[3, 4]}
		{...props}
		sx={{ fontSize: [3, 3, 4, "1.5vw"], ...props.sx }}
	/>
);

const Section = props => (
	<Parallax y={[-10, 10]}>
		<Box
			as='section'
			data-scroll-section
			{...props}
			mb={["30%", 4, 5, 6]}
			pb={["30%", 4, 5, 6]}
		/>
	</Parallax>
);

const Index = ({ pageContext: { locale }, location }) => {
	const lang = React.useContext(LocaleContext);
	const i18n = lang.i18n[lang.locale];

	const [timeFormatted, setTimeFormatted] = React.useState(
		dayjs().format("HH:mm:ss")
	);

	// Email Sign Up
	const [email, setEmail] = React.useState("");
	const [success, setSuccess] = React.useState(0);
	const [pending, setPending] = React.useState<null | boolean>(null);

	useInterval(() => setTimeFormatted(dayjs().format("HH:mm:ss")), 1000);

	const handleSubmit = e => {
		e.preventDefault();

		const STAGING_PREORDER_API = `https://staging.supertime.app/api/emailSubscribe?email=${email}`;
		const PRODUCTION_PREORDER_API = `https://web.supertime.app/api/emailSubscribe?email=${email}`;

		const API =
			process.env.NODE_ENV === "development"
				? STAGING_PREORDER_API
				: PRODUCTION_PREORDER_API;

		setPending(true);
		fetch(API)
			.then(response => response.json())
			.then((d: { status: number }) => {
				setPending(true);
				setSuccess(d.status);
			})
			.catch(e => {
				if (e) console.log(e);
				setPending(null);
				//setSuccess(e.status);
			});
	};

	const handleEmail = e => {
		setEmail(e.target.value);
	};

	const scrollToOrder = () => {
		if (typeof window !== undefined) {
			document
				.querySelector("#PREORDER")
				.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	// Preorder
	const [displayPreorder, setDisplayPreorder] = React.useState(false);

	return (
		<Box p={[3, 4]}>
			<SEO pathname={location.pathname} locale={locale} />

			<Header />

			<Section>
				<div
					data-scroll-speed='-20'
					data-scroll-offset='0'
					sx={{
						position: "relative",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						mx: "auto"
					}}>
					<Box
						color='black'
						sx={{
							zIndex: 1,
							fontSize: ["20vw"],
							letterSpacing: "-0.015em",
							color: "black",
							"-webkit-text-fill-color": "white",
							"-webkit-text-stroke-width": "1px",
							"-webkit-text-stroke-color": "black",
							"font-variant-numeric": "tabular-nums"
						}}
						mb={[4]}>
						{[...timeFormatted].map((l, i) => {
							return (
								<span key={i} style={{ display: "inline-block" }}>
									<Parallax y={[(i + 5) * 10, (i + 5) * -10]}>{l}</Parallax>
								</span>
							);
						})}
					</Box>

					<Box sx={{ position: "absolute", top: ["-20vh"] }}>
						<Box
							sx={{
								width: [200],
								height: [200],
								background: "conic-gradient(#f1f1f1, rgba(255, 255, 255, 0))",
								borderRadius: "50%",
								transform: `rotate(${6 * dayjs().get("s")}deg)`
							}}
						/>
					</Box>

					<Box
						sx={{
							zIndex: 2,
							width: "100%",
							maxWidth: [1200],
							borderRadius: 4,
							overflow: "hidden",
							boxShadow: `0px 25px 50px rgba(0, 0, 0, 0.25)`,
							mb: [3, 4, 5],
							mt: ["-10vh", "-10vh", "-25vh"]
						}}>
						<Image filename={"Overview 2.2@3x.png"} />
					</Box>

					<Text
						color='black'
						sx={{
							position: "absolute",
							fontSize: ["8vw", "4vw"],
							letterSpacing: "-0.015em",
							textTransform: "uppercase",
							color: "black",
							left: ["0"],
							top: ["30vh", "40vh", "75vh"],
							transform: `rotate(90deg) translateX(-50%)`,
							transformOrigin: "left center",
							"-webkit-text-fill-color": "white",
							"-webkit-text-stroke-width": "1px",
							"-webkit-text-stroke-color": "black",
							"font-variant-numeric": "tabular-nums"
						}}>
						<Parallax y={[-50, 50]}>Work less</Parallax>
					</Text>
					<Text
						color='black'
						sx={{
							position: "absolute",
							fontSize: ["8vw", "4vw"],
							letterSpacing: "-0.015em",
							textTransform: "uppercase",
							color: "black",
							right: ["0"],
							top: ["30vh", "40vh", "75vh"],
							transform: `rotate(90deg) translateX(50%)`,
							transformOrigin: "right center",
							"-webkit-text-fill-color": "white",
							"-webkit-text-stroke-width": "1px",
							"-webkit-text-stroke-color": "black",
							"font-variant-numeric": "tabular-nums"
						}}>
						<Parallax y={[50, -50]}>Do more</Parallax>
					</Text>

					<H mt={[3]}>Time is running. Reach your goals.</H>

					<Box my={[3, 4]} sx={{ maxWidth: "900px", width: "100%" }}>
						<P color='gray.800' mb={3}>
							We redefine how you review your goals, time, data and we do this
							with full privacy.
						</P>

						<S sx={{ textAlign: "center" }}>Available for Mac and Windows.</S>

						<Flex mt='3' sx={{ flexDirection: "column", textAlign: "center" }}>
							<Button
								bg='white'
								color='black'
								p={[2, 3]}
								sx={{
									cursor: "pointer",
									border: "1px solid transparent",
									borderColor: "black",
									textTransform: "uppercase",
									letterSpacing: "0.1em",
									borderRadius: 0,
									whiteSpace: "nowrap",
									"&:hover": {
										bg: "black",
										color: "white"
									}
								}}
								onClick={scrollToOrder}>
								Preorder for early access
							</Button>

							<S mt={3}>Preorder now for a guaranteed early access seat.</S>
						</Flex>
					</Box>
				</div>
			</Section>

			<Section>
				<H>How much is your time worth?</H>

				<Box mb={[3, 4]} sx={{ maxWidth: "900px", width: "100%", mx: "auto" }}>
					<P>
						Our automatic time tracking and analytics dashboard will help you do
						the right things at the right time.
						<br />
						<br />
						You will never loose track of a single minute.
					</P>
				</Box>
			</Section>

			<Section>
				<H>How much is your data worth?</H>

				<Box mb={[3, 4]} sx={{ maxWidth: "900px", width: "100%", mx: "auto" }}>
					<P>
						You always have full control and full rights over all your data at
						all times. We collect your application time spent, calendar events,
						health data, location data.
						<br />
						<br />
						Review your data and reevaluate your next step.
					</P>
				</Box>
			</Section>

			<Section>
				<H>How much is your privacy worth?</H>

				<Box mb={[3, 4]} sx={{ maxWidth: "900px", width: "100%", mx: "auto" }}>
					<P>
						Your data is forever only yours. We never sell, distribute or share
						your data with anybody else than you. Your data is only viewable by
						you. And fully encrypted when stored.
						<br />
						<br />
						You have full rights to your data and can request a download or
						deletion of all your data at any time.
					</P>
				</Box>
			</Section>

			<Section>
				<H mb={[4]}>Core Features</H>

				<Flex
					sx={{
						flexDirection: ["column", "row", "row"],
						justifyContent: "center",
						alignItems: ["center", "center", "flex-start"]
					}}>
					<Box sx={{ width: ["100%", "33.333%", "33.333%"], p: [0, 3] }}>
						<Flex mb={[3]} sx={{ justifyContent: "center" }}>
							<ScoreIcon />
						</Flex>
						<P mb={[3]} sx={{ textAlign: "center" }}>
							Automatic Score
						</P>
						<S>
							Our intelligent algorithms calculate your goals based on
							productivity, time spent, tasks completed and goals achieved.
							<br />
							<br />
							Do less. Do better.
						</S>
					</Box>

					<Box sx={{ width: ["100%", "33.333%", "33.333%"], p: [0, 3] }}>
						<Flex mb={[3]} sx={{ justifyContent: "center" }}>
							<GoalIcon />
						</Flex>
						<P mb={[3]} sx={{ textAlign: "center" }}>
							Systematic Goals
						</P>
						<S>
							Our app helps you to set your goal. We help you to build a system
							to achieve it.
							<br />
							<br />
							Focus on your goals. We do the rest.
						</S>
					</Box>

					<Box sx={{ width: ["100%", "33.333%", "33.333%"], p: [0, 3] }}>
						<Flex mb={[3]} sx={{ justifyContent: "center" }}>
							<TaskIcon />
						</Flex>
						<P mb={[3]} sx={{ textAlign: "center" }}>
							Task based
						</P>
						<S>
							Do the things that matter for your goals. Complete your tasks and
							stay productive.
							<br />
							<br />
							Each step and each second counts.
						</S>
					</Box>
				</Flex>
			</Section>

			<Section>
				<H>Roadmap</H>

				<Box mb={[3, 4]} sx={{ maxWidth: "900px", width: "100%", mx: "auto" }}>
					<S sx={{ textAlign: "center" }}>Upcoming</S>

					<P>Google Calendar events</P>
					<P>Device syncing</P>
					<P>iOS & Andoid support</P>
					<P>Health and fitness data</P>
					<P>Location tracking</P>
					<P>Custom tracking and annotations</P>

					<S sx={{ textAlign: "center" }}>Core Features</S>

					<P>Systematic goal setting</P>
					<P>Task based tracking</P>
					<P>Automatic score evaluation</P>
					<P>Automatic time tracking</P>
				</Box>
			</Section>

			<Section id='PREORDER'>
				<H>Pre-order</H>
				<P sx={{ textAlign: "center" }}>
					Limited discount.
					<br />
					Free refund. Cancel anytime.
				</P>
				<Flex
					sx={{
						alignItems: "baseline",
						justifyContent: "center",
						flexWrap: "wrap"
					}}>
					<H sx={{ my: [0, 0, 0, 0] }}>
						<Box sx={{ textDecoration: "line-through", mr: [4] }} as='span'>
							40USD
						</Box>
						25USD
					</H>
					<S>/Month</S>
				</Flex>
				<S sx={{ textAlign: "center" }}>
					Only pay when you start using. <br /> Billed annually. Total
					<Box sx={{ textDecoration: "line-through", mx: [2] }} as='span'>
						480USD
					</Box>
					300USD
				</S>
				<AnimatePresence>
					{!displayPreorder ? (
						<Button
							bg='white'
							color='black'
							mt={[2, 0]}
							p={[2, 3]}
							sx={{
								mx: "auto",
								display: "block",
								cursor: "pointer",
								border: "1px solid transparent",
								borderColor: "black",
								textTransform: "uppercase",
								letterSpacing: "0.1em",
								borderRadius: 0,
								whiteSpace: "nowrap",
								minWidth: "30%",
								"&:hover": {
									bg: "black",
									color: "white"
								}
							}}
							onClick={() => setDisplayPreorder(true)}>
							Pre-order Now
						</Button>
					) : (
						<StripePreorder />
					)}
				</AnimatePresence>
			</Section>

			<Section>
				<Box mx='auto' my={[3, 4]} sx={{ maxWidth: "900px", width: "100%" }}>
					{success === 200 && (
						<P mb={[2, 3]} color='green.600'>
							Your request has been sent.
						</P>
					)}
					{success !== 0 && success !== 200 && (
						<P color='red.600'>Something went wrong. Please try again.</P>
					)}
					{pending && success === 0 && <P>Request is being sent.</P>}
					{pending === null && pending !== true && (
						<>
							<S color='gray.400' sx={{ textAlign: "center" }}>
								Stay updated for the release.
							</S>
							<Flex
								as='form'
								onSubmit={handleSubmit}
								sx={{ flexDirection: ["column", "row"] }}>
								<Input
									type='email'
									placeholder={"ENTER EMAIL"}
									color='black'
									value={email}
									onChange={handleEmail}
									p={[2, 3]}
									sx={{
										border: "0px solid transparent",
										borderBottom: "1px solid",
										borderColor: "black",
										borderRadius: 0,
										"&::placeholder": {
											letterSpacing: "0.1em"
										}
									}}
								/>
								<Button
									bg='white'
									color='black'
									mt={[2, 0]}
									ml={[0, 3]}
									p={[2, 3]}
									sx={{
										cursor: "pointer",
										border: "1px solid transparent",
										borderColor: "black",
										textTransform: "uppercase",
										letterSpacing: "0.1em",
										borderRadius: 0,
										whiteSpace: "nowrap",
										minWidth: "30%",
										"&:hover": {
											bg: "black",
											color: "white"
										}
									}}>
									Sign up
								</Button>
							</Flex>
						</>
					)}
				</Box>
			</Section>

			<Section>
				<S sx={{ textAlign: "center" }}>Contact</S>
				<H sx={{ fontSize: [3, 4, 5, 6] }}>
					<a href='mailto:hello@supertime.app'>hello@supertime.app</a>
				</H>
			</Section>

			<Section>
				<S sx={{ textAlign: "center" }}>Social</S>
				<Flex sx={{ justifyContent: "center" }}>
					<Box p={[3]}>
						<a href='https://twitter.com/SupertimeApp'>Twitter</a>
					</Box>
					<Box p={[3]}>
						<a href='https://www.instagram.com/supertimeapp/'>Instagram</a>
					</Box>
					<Box p={[3]}>
						<a href='https://www.linkedin.com/company/supertimeapp'>LinkedIn</a>
					</Box>
				</Flex>
			</Section>

			<Section style={{ marginBottom: 0, paddingBottom: 0 }}>
				<S sx={{ textAlign: "center" }}>© supertime 2020</S>

				{process.env.NODE_ENV === "production" ? (
					<S sx={{ textAlign: "center", fontSize: [0, 0, 0, 0] }}>
						Work less. Do more.
					</S>
				) : (
					<S sx={{ textAlign: "center", fontSize: [0] }}>Development</S>
				)}
			</Section>
		</Box>
	);
};

export default Index;
